import React from "react"

import { graphql } from "gatsby"

import PropTypes from "prop-types"

import ProductList from "../components/products/ProductList"
import SEO from "../components/seo"
import { getValidCategoryProducts } from "../utils/methods"

const ProductSubType = ({ data }) => {
  const { productType } = data.saleor

  const [validCategoryProducts, setValidCategoryProducts] = React.useState([])

  React.useEffect(() => {
    if (productType.products) {
      setValidCategoryProducts(getValidCategoryProducts(productType.products))
    }
  }, [productType])

  return (
    <>
      <SEO
        title={`Products - ${productType.name}`}
        path={`/products/${productType.slug}/`}
      />
      <ProductList hasHeader={false} products={validCategoryProducts} />
    </>
  )
}

export const pageQuery = graphql`
  query ProductSubTypeBySlug($id: ID!) {
    saleor {
      productType(id: $id) {
        id
        slug
        name
        products(first: 100) {
          edges {
            node {
              id
              name
              slug
              isAvailable
              isAvailableForPurchase
              pricing {
                discount {
                  gross {
                    amount
                    currency
                  }
                }
              }
              category {
                name
              }
              variants {
                name
                id
                pricing {
                  price {
                    gross {
                      amount
                      currency
                    }
                  }
                }
              }
              thumbnail {
                url
                alt
              }
            }
          }
        }
      }
    }
  }
`

ProductSubType.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ProductSubType
